
import { defineComponent, onMounted } from 'vue';
import TopCoverClasses from '../components/classes/TopCoverClasses.vue';
import OpenRegistration from '../components/classes/OpenRegistration.vue';
import IndividualClasses from '../components/classes/IndividualClasses.vue';
import FreeClass from '../components/FreeClass.vue';

export default defineComponent({
  name: 'Classes',
  components: {
    TopCoverClasses,
    OpenRegistration,
    IndividualClasses,
    FreeClass,
  },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
    };
  },
});
