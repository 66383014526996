import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "companies" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopCoverCompanies = _resolveComponent("TopCoverCompanies")!
  const _component_CompaniesServices = _resolveComponent("CompaniesServices")!
  const _component_ChatterCoServices = _resolveComponent("ChatterCoServices")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_TopCoverCompanies),
    _createVNode(_component_CompaniesServices),
    _createVNode(_component_ChatterCoServices)
  ]))
}