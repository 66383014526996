const groupClasses = {
  testClass: 'sin costo',
  levels: {
    beginner: {
      name: 'Beginner - A0',
      route: 'beginner',
      image: require('@/assets/groupLevelImages/Beginner/BeginnersGroups.webp'),
      description: 'Los grupos BEGINNER tienen nombres de chicle, porque acá empecemos a masticar… En nivel BEGINNER comenzamos con inglés desde cero, y con cero miedo de aprender!',
      rhythm: 'tranqui',
      frequency: '1,5 horas x clase | 2 clases x semana',
      itIsForMeIf: [
        'Sentís que el inglés no es para vos (te cambiamos de opinión, ohhsi)',
        'Nunca tomaste una clase de inglés en tu vida',
        'Hace 20+ años que no estudiás inglés',
      ],
      monthlyHourPackages: '12 horas (appx)',
      duration: {
        oneMonth: {
          price: '$ARS 7.600',
        },
        threeMonths: {
          price: '$ARS 17.200',
        },
        sixMonths: {
          price: '$ARS 31.000',
        },
      },
      groups: [
        {
          groupName: 'Bazooca Group',
          groupRoute: 'bazoocaGroup',
          image: require('@/assets/groupLevelImages/Beginner/Bazooca.webp'),
          schedule: 'Martes y Jueves de 17 a 18:30hs',
          startsOn: '11/01/2022',
          available: true,
          showDetails: false,
        },
        {
          groupName: 'Juicy Fruit Group',
          groupRoute: 'juicyFruitGroup',
          image: require('@/assets/groupLevelImages/Beginner/JuicyFruit.webp'),
          schedule: 'Lunes y Miércoles de 19 a 20:30hs',
          startsOn: '11/01/2022',
          available: false,
          showDetails: false,
        },
      ],
    },
    elementary:
      {
        name: 'Elementary - A1',
        route: 'elementary',
        image: require('@/assets/groupLevelImages/Elementary/ElementaryGroups.webp'),
        description: 'En ELEMENTARY empezamos a darle sabor a nuestro pequeño inglés. Por eso los grupos elementary tienen nombres de condimentos!',
        rhythm: 'vamo` despacio',
        frequency: '1 hora x clase | 2 clases x semana',
        itIsForMeIf: [
          'Recordás un poquito de inglés de la primaria',
          'Te sentís "bloqueado" con el inglés pero tenés ganas igual!',
          'You can read and understand this little sentence',
        ],
        monthlyHourPackages: '9 horas appx',
        duration: {
          oneMonth: {
            price: '$ARS 5.400',
          },
          threeMonths: {
            price: '$ARS 12.800',
          },
          sixMonths: {
            price: '$ARS 22.700',
          },
        },
        groups: [
          {
            groupName: 'Tabasco Group',
            groupRoute: 'tabascoGroup',
            image: require('@/assets/groupLevelImages/Elementary/Tabasco.webp'),
            schedule: 'Martes y Jueves de 13 a 14hs',
            startsOn: '11/01/2022',
            available: false,
            showDetails: false,
          },
          {
            groupName: 'Ketchup Group',
            groupRoute: 'ketchupGroup',
            image: require('@/assets/groupLevelImages/Elementary/Ketchup.webp'),
            schedule: 'Martes y Jueves de 19 a 20hs',
            startsOn: '11/01/2022',
            available: true,
            showDetails: false,
          },
        ],
      },
    preIntermediate:
      {
        name: 'Pre Intermediate - A2',
        route: 'preIntermediate',
        image: require('@/assets/groupLevelImages/PreIntermediate/PreIntermediateGroups.webp'),
        description: 'Acá se pica! Por eso los grupos Pre Intermediate tienen nombres de nuestros snacks favoritos.',
        rhythm: 'entramos en calor',
        frequency: '1 hora x clase | 2 clases x semana',
        itIsForMeIf: [
          'Tomaste clases en el colegio',
          'Entendés y contestás preguntas básicas',
          'Podés contar una anécdota en el pasado',
        ],
        monthlyHourPackages: '9 horas appx',
        duration: {
          oneMonth: {
            price: '$ARS 5.400',
          },
          threeMonths: {
            price: '$ARS 12.800',
          },
          sixMonths: {
            price: '$ARS 22.700',
          },
        },
        groups: [
          {
            groupName: 'Nachos Group',
            groupRoute: 'nachosGroup',
            image: require('@/assets/groupLevelImages/PreIntermediate/Nachos.webp'),
            schedule: 'Lunes y Miércoles de 17:30 a 18:30hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Cheese Fries Group',
            groupRoute: 'cheeseFriesGroup',
            image: require('@/assets/groupLevelImages/PreIntermediate/CheeseFries.webp'),
            schedule: 'Martes y Jueves de 19:30 a 20:30hs',
            startsOn: '11/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Oreo Group',
            groupRoute: 'oreoGroup',
            image: require('@/assets/groupLevelImages/PreIntermediate/Oreo.webp'),
            schedule: 'Martes y Jueves de 17:30 a 18:30hs',
            startsOn: '11/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Honey Mustard Group',
            groupRoute: 'honeyMustardGroup',
            image: require('@/assets/groupLevelImages/PreIntermediate/HoneyMustard.webp'),
            schedule: 'Lunes y Miércoles de 18:30 a 19:30hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
        ],
      },
    intermediate:
      {
        name: 'Intermediate - B1',
        route: 'intermediate',
        image: require('@/assets/groupLevelImages/Intermediate/IntermediateGroups.webp'),
        description: 'Acá se pone más lindo y empezamos a divertirnos el inglés! Por eso los grupos Intermediate son nombrados por Candy (golosinas)',
        rhythm: 'entramos en calor',
        frequency: '1 hora x clase | 2 clases x semana',
        itIsForMeIf: [
          'Usás el inglés a veces',
          'Te defendés en una conversación sin problemas graves',
          'Te bancás las series y pelis con subtítulos en inglés',
        ],
        monthlyHourPackages: '9 horas appx',
        duration: {
          oneMonth: {
            price: '$ARS 5.400',
          },
          threeMonths: {
            price: '$ARS 12.800',
          },
          sixMonths: {
            price: '$ARS 22.700',
          },
        },
        groups: [
          {
            groupName: 'Reeses Group',
            groupRoute: 'reesesGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Reeses.webp'),
            schedule: 'Lunes y Miércoles de 12 a 13hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'KitKat Group',
            groupRoute: 'kitKatGroup',
            image: require('@/assets/groupLevelImages/Intermediate/KitKat.webp'),
            schedule: 'Lunes y Miércoles de 18 a 19hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Skittles Group',
            groupRoute: 'skittlesGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Skittles.webp'),
            schedule: 'Lunes y Miércoles de 19 a 20hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Butterfinger Group',
            groupRoute: 'butterfingerGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Butterfinger.webp'),
            schedule: 'Martes y Jueves de 19 a 20hs',
            startsOn: '11/01/2022',
            available: false,
            showDetails: false,
          },
          {
            groupName: 'Twizzlers Group',
            groupRoute: 'twizzlersGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Twizzlers.webp'),
            schedule: 'Martes y Jueves de 19:30 a 20:30hs',
            startsOn: '11/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Twix Group',
            groupRoute: 'twixGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Twix.webp'),
            schedule: 'Martes y Jueves de 20 a 21hs',
            startsOn: '11/01/2022',
            available: false,
            showDetails: false,
          },
          {
            groupName: 'Snickers Group',
            groupRoute: 'snickersGroup',
            image: require('@/assets/groupLevelImages/Intermediate/Snickers.webp'),
            schedule: 'Martes y Jueves de 18:30 a 19:30hs',
            startsOn: '11/01/2022',
            available: false,
            showDetails: false,
          },
        ],
      },
    upperIntermediate:
      {
        name: 'Upper Intermediate - B2',
        route: 'upperIntermediate',
        image: require('@/assets/groupLevelImages/UpperIntermediate/UpperIntermediateGroups.webp'),
        description: 'El inglés se disfruta en Upper Intermediate! Acá empezamos con lo más rico, por lo cual los grupos son nombrados por desserts.',
        rhythm: 'agitado',
        frequency: '1,5 hora x clase | 1 clase x semana',
        itIsForMeIf: [
          'Usás el inglés con frecuencia',
          'Te comunicás en inglés con pocos errores y problemas',
          'Te bancás las series y pelis SIN subtítulos en inglés',
        ],
        monthlyHourPackages: '6 horas appx',
        duration: {
          oneMonth: {
            price: '$ARS 4.400',
          },
          threeMonths: {
            price: '$ARS 9.800',
          },
          sixMonths: {
            price: '$ARS 17.200',
          },
        },
        groups: [
          {
            groupName: 'Donut Group',
            groupRoute: 'domutGroup',
            image: require('@/assets/groupLevelImages/UpperIntermediate/Donut.webp'),
            schedule: 'Miércoles de 12 a 13:30hs',
            startsOn: '12/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Lemon Custard Group',
            groupRoute: 'lemonCustardGroup',
            image: require('@/assets/groupLevelImages/UpperIntermediate/LemonCustard.webp'),
            schedule: 'Lunes de 17:30 a 19hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Cupcake Group',
            groupRoute: 'cupcakeGroup',
            image: require('@/assets/groupLevelImages/UpperIntermediate/Cupcake.webp'),
            schedule: 'Jueves de 19 a 20:30hs',
            startsOn: '13/01/2022',
            available: true,
            showDetails: false,
          },
          {
            groupName: 'Banana Split Group',
            groupRoute: 'bananaSplitGroup',
            image: require('@/assets/groupLevelImages/UpperIntermediate/BananaSplit.webp'),
            schedule: 'Lunes de 19 a 20:30hs',
            startsOn: '10/01/2022',
            available: true,
            showDetails: false,
          },
        ],
      },
    advanced:
      {
        name: 'Advanced',
        route: 'advanced',
        image: require('@/assets/groupLevelImages/Advanced/AdvancedGroups.webp'),
        description: 'Vamos con todo! Advanced groups son como los desserts más elaborados - se requieren mucha esfuerza para llegar al producto final, pero dios que rico que es! El inglés en su forma completa y exquisita.',
        rhythm: 'exigente',
        frequency: '1,5 hora x clase | 1 clase x semana',
        itIsForMeIf: [
          'Sos pro y querés mantener la fluidez',
          'Querés perfeccionar y hacerte más "nativo" en inglés',
          'Te podrás casar con un yanqui y vivir toda la relación en inglés tranqui',
        ],
        monthlyHourPackages: '6 horas appx',
        duration: {
          oneMonth: {
            price: '$ARS 4.400',
          },
          threeMonths: {
            price: '$ARS 9.800',
          },
          sixMonths: {
            price: '$ARS 17.200',
          },
        },
        groups: [
          {
            groupName: 'Hot Fudge Sundae Group',
            groupRoute: 'hotFudgeSundaeGroup',
            image: require('@/assets/groupLevelImages/Advanced/HotFudgeSundae.webp'),
            schedule: 'Martes de 17 a 18:30hs',
            startsOn: '11/01/2022',
            available: false,
            showDetails: false,
          },
          {
            groupName: 'Peach Pie Group',
            groupRoute: 'peachPieGroup',
            image: require('@/assets/groupLevelImages/Advanced/PeachPie.webp'),
            schedule: 'Miércoles de 18:30 a 20hs',
            startsOn: '12/01/2022',
            available: false,
            showDetails: false,
          },
          {
            groupName: 'Milkshake Group',
            groupRoute: 'milkshakeGroup',
            image: require('@/assets/groupLevelImages/Advanced/Milkshake.webp'),
            schedule: 'Miercoles de 19:30 a 21hs',
            startsOn: '12/01/2022',
            available: true,
            showDetails: false,
          },
        ],
      },
  },
};

export default groupClasses;
