
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'GroupLevelBackCard',
  props: ['thisGroup'],
  setup(props) {
    const group = ref(props.thisGroup);

    return {
      group,
    };
  },
});
