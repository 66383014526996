
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'LevelDescription',
  props: ['levelData'],
  setup(props) {
    const level = ref(props.levelData);

    return {
      level,
    };
  },
});
