
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'WallGuardHome',
  props: ['guardInformation'],
  setup(props) {
    const firstItem = ref(props.guardInformation.first);
    const secondItem = ref(props.guardInformation.second);
    const thirdItem = ref(props.guardInformation.third);
    const fourthItem = ref(props.guardInformation.fourth);

    return {
      firstItem,
      secondItem,
      thirdItem,
      fourthItem,
    };
  },
});
