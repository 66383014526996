import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-082c2838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }
const _hoisted_2 = {
  key: 0,
  class: "carousel__navigation"
}
const _hoisted_3 = { class: "navigation__togglePage left" }
const _hoisted_4 = { class: "navigation__togglePage right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { currentSlide: _ctx.currentSlide }, undefined, true),
    (_ctx.navigationEnabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("i", {
              id: "previousITag",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePreviousOnClick && _ctx.handlePreviousOnClick(...args))),
              class: "fas fa-chevron-left"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("i", {
              id: "nextITag",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleNextOnClick && _ctx.handleNextOnClick(...args))),
              class: "fas fa-chevron-right"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}