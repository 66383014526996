
import { defineComponent } from 'vue';
import groupClasses from '../../assets/constants/groupClasses';

export default defineComponent({
  name: 'OpenRegistration',
  setup() {
    const groupsLevel = groupClasses;
    return {
      groupsLevel,
    };
  },
});
