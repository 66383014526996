import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import GroupLevelInformation from '../views/GroupLevelInformation.vue';
import Home from '../views/Home.vue';
import Classes from '../views/Classes.vue';
import Companies from '../views/Companies.vue';
import Blog from '../views/Blog.vue';
import ContactUs from '../views/ContactUs.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/classes',
    name: 'Classes',
    component: Classes,
  },
  {
    path: '/classes/:groupLevelName',
    name: 'GroupLevelInformation',
    component: GroupLevelInformation,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
