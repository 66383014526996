
import { defineComponent, onMounted } from 'vue';
import TopCoverCompanies from '../components/companies/TopCoverCompanies.vue';
import CompaniesServices from '../components/companies/CompaniesServices.vue';
import ChatterCoServices from '../components/companies/ChatterCoServices.vue';

export default defineComponent({
  name: 'Companies',
  components: {
    TopCoverCompanies,
    CompaniesServices,
    ChatterCoServices,
  },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });
  },
});
