
import { defineComponent, ref } from 'vue';
import VueFlip from 'vue-flip';
import ServicesFrontCard from './ServicesFrontCard.vue';
import ServicesBackCard from './ServicesBackCard.vue';

export default defineComponent({
  name: 'ServicesCardSection',
  components: {
    ServicesFrontCard,
    ServicesBackCard,
    'vue-flip': VueFlip,
  },
  props: ['thisService'],
  setup(props) {
    const service = ref(props.thisService);

    return {
      service,
    };
  },
});
