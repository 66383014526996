
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ServicesBackCard',
  props: ['thisService'],
  setup(props) {
    const serviceData = ref(props.thisService);

    return {
      serviceData,
    };
  },
});
