import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "servicesCardSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupLevelFrontCard = _resolveComponent("GroupLevelFrontCard")!
  const _component_GroupLevelBackCard = _resolveComponent("GroupLevelBackCard")!
  const _component_vue_flip = _resolveComponent("vue-flip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_vue_flip, {
      "active-click": "",
      height: "24rem",
      width: "20rem"
    }, {
      front: _withCtx(() => [
        _createVNode(_component_GroupLevelFrontCard, { thisGroup: _ctx.group }, null, 8, ["thisGroup"])
      ]),
      back: _withCtx(() => [
        _createVNode(_component_GroupLevelBackCard, { thisGroup: _ctx.group }, null, 8, ["thisGroup"])
      ]),
      _: 1
    })
  ]))
}