import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopCoverHome = _resolveComponent("TopCoverHome")!
  const _component_PresentationHome = _resolveComponent("PresentationHome")!
  const _component_WallGuard = _resolveComponent("WallGuard")!
  const _component_RegistrationFee = _resolveComponent("RegistrationFee")!
  const _component_WhatIsChatterbox = _resolveComponent("WhatIsChatterbox")!
  const _component_Methodology = _resolveComponent("Methodology")!
  const _component_TheTeachers = _resolveComponent("TheTeachers")!
  const _component_ClassChecklist = _resolveComponent("ClassChecklist")!
  const _component_StudentsReviews = _resolveComponent("StudentsReviews")!
  const _component_FreeClass = _resolveComponent("FreeClass")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_TopCoverHome),
    _createVNode(_component_PresentationHome),
    _createVNode(_component_WallGuard, { guardInformation: _ctx.homeGuardInfo }, null, 8, ["guardInformation"]),
    _createVNode(_component_RegistrationFee),
    _createVNode(_component_WhatIsChatterbox),
    _createVNode(_component_Methodology),
    _createVNode(_component_TheTeachers),
    _createVNode(_component_ClassChecklist),
    _createVNode(_component_StudentsReviews),
    _createVNode(_component_FreeClass)
  ]))
}