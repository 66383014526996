
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FreeClass',
  setup() {
    const startFreeClass = () => {
      window.open('https://forms.gle/zMtWEVHXqzucYKo89', '_self');
    };

    return {
      startFreeClass,
    };
  },
});
