
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RegistrationFee',
  setup() {
    const checkLevel = () => {
      window.open('https://forms.gle/QkrqtgiVnJteMd8V7', '_self');
    };
    const startFreeClass = () => {
      window.open('https://forms.gle/zMtWEVHXqzucYKo89', '_self');
    };

    return {
      checkLevel,
      startFreeClass,
    };
  },
});
