import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c27b810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wallGuard" }
const _hoisted_2 = { class: "wallGuard__box" }
const _hoisted_3 = { class: "wallGuard__item real" }
const _hoisted_4 = { class: "item__vector" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "wallGuard__item native" }
const _hoisted_7 = { class: "item__vector" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { id: "native" }
const _hoisted_10 = { class: "wallGuard__box" }
const _hoisted_11 = { class: "wallGuard__item oral" }
const _hoisted_12 = { class: "item__vector" }
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = { class: "wallGuard__item real adult" }
const _hoisted_15 = { class: "item__vector" }
const _hoisted_16 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "vector__image",
            src: _ctx.firstItem.imageGuard,
            alt: _ctx.firstItem.imageAlt
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.firstItem.titleGuard), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.firstItem.paragraphGuard), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            class: "vector__image",
            src: _ctx.secondItem.imageGuard,
            alt: _ctx.secondItem.imageAlt
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.secondItem.titleGuard), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.secondItem.paragraphGuard), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("img", {
            class: "vector__image",
            src: _ctx.thirdItem.imageGuard,
            alt: _ctx.thirdItem.imageAlt
          }, null, 8, _hoisted_13)
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.thirdItem.titleGuard), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.thirdItem.paragraphGuard), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("img", {
            class: "vector__image",
            src: _ctx.fourthItem.imageGuard,
            alt: _ctx.fourthItem.imageAlt
          }, null, 8, _hoisted_16)
        ]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.fourthItem.titleGuard), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.fourthItem.paragraphGuard), 1)
      ])
    ])
  ]))
}