
import { defineComponent, onMounted } from 'vue';
import homeGuard from '../assets/constants/homeGuardInformation';
import TopCoverHome from '../components/home/TopCoverHome.vue';
import PresentationHome from '../components/home/PresentationHome.vue';
import WallGuard from '../components/WallGuard.vue';
import RegistrationFee from '../components/RegistrationFee.vue';
import WhatIsChatterbox from '../components/home/WhatIsChatterbox.vue';
import Methodology from '../components/home/Methodology.vue';
import TheTeachers from '../components/home/TheTeachers.vue';
import ClassChecklist from '../components/home/ClassChecklist.vue';
import StudentsReviews from '../components/home/StudentsReviews.vue';
import FreeClass from '../components/FreeClass.vue';

export default defineComponent({
  name: 'Home',
  components: {
    TopCoverHome,
    PresentationHome,
    WallGuard,
    RegistrationFee,
    WhatIsChatterbox,
    Methodology,
    TheTeachers,
    ClassChecklist,
    StudentsReviews,
    FreeClass,
  },
  setup() {
    const homeGuardInfo = homeGuard;

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      homeGuardInfo,
    };
  },
});
