
import { defineComponent, ref } from 'vue';
import VueFlip from 'vue-flip';
import GroupLevelFrontCard from './GroupLevelFrontCard.vue';
import GroupLevelBackCard from './GroupLevelBackCard.vue';

export default defineComponent({
  name: 'GroupLevelFlipCard',
  components: {
    GroupLevelFrontCard,
    GroupLevelBackCard,
    'vue-flip': VueFlip,
  },
  props: ['thisGroup'],
  setup(props) {
    const group = ref(props.thisGroup);
    return {
      group,
    };
  },
});
