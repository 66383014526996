import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae77d96c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "groupLevelFrontCard" }
const _hoisted_2 = { class: "groupLevelFrontCard__image" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  class: "groupLevelFrontCard__disponibility"
}
const _hoisted_5 = {
  key: 1,
  class: "groupLevelFrontCard__disponibility"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.group.image,
        alt: `Poster de ${_ctx.group.name}`,
        class: "image__image"
      }, null, 8, _hoisted_3)
    ]),
    (_ctx.group.available === true)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_4, " Cupos Disponibles! "))
      : (_openBlock(), _createElementBlock("h3", _hoisted_5, " Sin Cupos! "))
  ]))
}