
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'TeacherCarousel',
  props: ['timeout', 'navigation'],
  setup(props) {
    const currentSlide = ref(0);
    const getSlideCount = ref();
    let autoPlayEnabled = true;
    const timeoutDuration = ref(props.timeout === undefined ? 3000 : props.timeout);
    const navigationEnabled = ref(props.navigation === undefined ? true : props.navigation);

    const nextSlide = () => {
      if (currentSlide.value === getSlideCount.value) {
        currentSlide.value = 0;
        return;
      }
      currentSlide.value += 1;
    };

    const previousSlide = () => {
      if (currentSlide.value === 0) {
        currentSlide.value = getSlideCount.value + 1;
      }
      currentSlide.value -= 1;
    };

    const autoPlay = () => {
      const myInterval = setInterval(() => {
        if (!autoPlayEnabled) {
          clearInterval(myInterval);
        } else {
          nextSlide();
        }
      }, timeoutDuration.value);
    };

    const handleNextOnClick = () => {
      nextSlide();
      autoPlayEnabled = false;
    };

    const handlePreviousOnClick = () => {
      previousSlide();
      autoPlayEnabled = false;
    };

    if (autoPlayEnabled) {
      autoPlay();
    }

    onMounted(() => {
      getSlideCount.value = document.querySelectorAll('.slide').length - 1;
    });

    return {
      currentSlide,
      nextSlide,
      previousSlide,
      autoPlay,
      autoPlayEnabled,
      navigationEnabled,
      handleNextOnClick,
      handlePreviousOnClick,
    };
  },
});
