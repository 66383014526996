
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PresentationHome',
  methods: {
    startFreeClass() {
      window.open('https://forms.gle/zMtWEVHXqzucYKo89', '_self');
    },
  },
});
