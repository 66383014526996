
import { defineComponent } from 'vue';
import chatterCoServices from '../../assets/constants/chatterCoServices';
import ServicesFlipCard from './ServicesFlipCard.vue';

export default defineComponent({
  name: 'ChatterCoServices',
  components: {
    ServicesFlipCard,
  },
  setup() {
    const companyServices = chatterCoServices;

    return {
      companyServices,
    };
  },
});
