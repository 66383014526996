const homeGuard = {
  first: {
    imageGuard: require('../vectors/treeLeaf.svg'),
    imageAlt: 'Vector tree leaf',
    titleGuard: 'REAL ENGLISH',
    paragraphGuard: 'inglés posta: naturál - útil - real',
  },
  second: {
    imageGuard: require('../vectors/shiningStar.svg'),
    imageAlt: 'Vector shining star',
    titleGuard: 'NATIVE ENGLISH',
    paragraphGuard: 'inglés nativo: acentos - expresiones - jerga',
  },
  third: {
    imageGuard: require('../vectors/talkCloud.svg'),
    imageAlt: 'Vector talk cloud',
    titleGuard: 'ORAL ENGLISH',
    paragraphGuard: 'inglés hablado: dejá los textos - soltá la lengua',
  },
  fourth: {
    imageGuard: require('../vectors/mustache.svg'),
    imageAlt: 'Vector mustache',
    titleGuard: 'ADULT ENGLISH',
    paragraphGuard: 'inglés adulto: temas actuales y relevantes solo para 18+ años',
  },
};

export default homeGuard;
