const chatterCoServices = [
  {
    name: 'CLASES',
    front: 'Capacitamos a tu compañía con el inglés que necesiten',
    showFront: true,
    back: [
      'Grupos Cerrados',
      'Grupos Chatter',
      'Clases Particulares',
    ],
    groups: [
      {
        closedGroups: {
          name: 'Grupos Cerrados',
          description: [
            '*Para enfocar el inglés a sus prioridades y necesidades',
            'Grupos exclusivos para sus colaboradores',
            'Organizados x nivel y/o especialización',
          ],
        },
      },
      {
        chatterGroups: {
          name: 'Grupos Chatter',
          description: [
            '*Para practicar y refinar el inglés general',
            'Participación en nuestros grupos de inglés',
            'Organizados x nivel',
            'Orientados al inglés conversacional y la práctica',
          ],
        },
      },
      {
        privateLessons: {
          name: 'Clases Particulares',
          description: [
            '*Para ejecutivos o individuales con objetivos específicos',
            'Clases completamente personalizadas para individuales',
          ],
        },
      },
    ],
  },
  {
    name: 'NIVELACIONES',
    front: 'Nivelamos el inglés de quienes quieran',
    showFront: true,
    back: [
      '*Para nivelar sus candidatos e ingresantes en el proceso de contratación',
      '*Para nivelar sus colaboradores antes de comenzar sus clases de inglés',
      'Entrevistas de nivelación individuales',
      'Chequeamos aptitudes lectura, escucha, auditivo y oral',
    ],
  },
  {
    name: 'EVALUACIONES',
    front: 'Chequeamos como va el aprendizaje',
    showFront: true,
    back: [
      '*Para evaluar el progreso de sus participantes',
      'Evaluaciónes orales, individuales o en pares',
      'Frecuencia mensual o semestral',
    ],
  },
  {
    name: 'INFORMES',
    front: 'Les mantenemos al tanto',
    showFront: true,
    back: [
      '*Para trackear los métricos que necesiten',
      'Informes de asistencia, participación y progreso de sus participantes',
      'Entregas mensuales o semestrales',
    ],
  },
];
export default chatterCoServices;
