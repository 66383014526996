
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Header',
  setup() {
    const mobileNav = ref(false);
    const mobileSize = ref(false);
    const windowWidth = ref(0);

    const checkScreen = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value <= 875) {
        mobileSize.value = true;
      } else {
        mobileSize.value = false;
      }
    };

    const toggleMobileNavigation = () => {
      mobileNav.value = !mobileNav.value;
    };

    window.addEventListener('load', () => checkScreen());

    window.addEventListener('resize', () => checkScreen());

    return {
      mobileNav,
      mobileSize,
      checkScreen,
      toggleMobileNavigation,
    };
  },
});
