import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "servicesCardSection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServicesFrontCard = _resolveComponent("ServicesFrontCard")!
  const _component_ServicesBackCard = _resolveComponent("ServicesBackCard")!
  const _component_vue_flip = _resolveComponent("vue-flip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_vue_flip, {
      "active-click": "",
      height: "17rem",
      width: "17rem"
    }, {
      front: _withCtx(() => [
        _createVNode(_component_ServicesFrontCard, { thisService: _ctx.service }, null, 8, ["thisService"])
      ]),
      back: _withCtx(() => [
        _createVNode(_component_ServicesBackCard, { thisService: _ctx.service }, null, 8, ["thisService"])
      ]),
      _: 1
    })
  ]))
}