
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import groupClasses from '../assets/constants/groupClasses';
import LevelDescription from '../components/classes/LevelDescription.vue';
import GroupLevelFlipCard from '../components/classes/GroupLevelFlipCard.vue';

export default defineComponent({
  name: 'GroupLevelInformation',
  components: {
    LevelDescription,
    GroupLevelFlipCard,
  },
  setup() {
    const route = useRoute();
    const chooseLevel: string = (route.params.groupLevelName) as string;
    const thisGroupLevel = (groupClasses.levels as any)[chooseLevel];

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      thisGroupLevel,
    };
  },
});
