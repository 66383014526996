
import { defineComponent, ref } from 'vue';
import TeacherCarousel from './TeacherCarousel.vue';
import TeachersSlide from './TeachersSlide.vue';

export default defineComponent({
  name: 'TheTeachers',
  components: {
    TeacherCarousel,
    TeachersSlide,
  },
  setup() {
    const carouselSlides = [
      'teacher1',
      'teacher2',
      'teacher3',
      'teacher4',
      'teacher5',
      'teacher6',
      'teacher7',
      'teacher8',
      'teacher9',
      'teacher10',
      'teacher11',
    ];
    const mobileSize = ref(false);
    const windowWidth = ref(0);

    const checkScreen = () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value <= 420) {
        mobileSize.value = true;
      } else {
        mobileSize.value = false;
      }
    };

    window.addEventListener('load', () => checkScreen());

    window.addEventListener('resize', () => checkScreen());

    return {
      carouselSlides,
      mobileSize,
      checkScreen,
    };
  },
});
